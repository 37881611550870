import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

function Boletin() {
  
  const [correo, setCorreo] = useState("");
  const [successMsgCss, setSuccessMsgCss] = useState(false);

  // async function onSubmit(e) {
  //   e.preventDefault();
  //   const data = {
  //     correo
  //   };
  //   // console.log(data)
  //   try {
  //     const res = await fetch("/api/boletin", {
  //       method: "POST",
  //       body: JSON.stringify(data),
  //       headers: {
  //         "content-type": "application/json",
  //         Accept: "application/json",
  //       },
  //     });
  //     if (!res.ok) {
  //       throw new Error("HTTP error! status: " + res.status);
  //     }
  //     const resData = await res.json();
  //     console.log(resData);
  //     setCorreo("");
  //     setSuccessMsgCss(true);
  //   } catch (err) {
  //     console.error("Error nene: " + err.message);
  //   }
  // }

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    const data = {
          correo,
        };

  emailjs.send('service_dxzof1g', 'template_tz33a3t', data, 'QzXcrbRMeQgiZ919G')
  .then((result) => {
      alert("Gracias por su mensaje");
      setCorreo("");
      setSuccessMsgCss(true);
  }, (error) => {
      console.log(error.text);
  });
};
  return (
    <div className="flex flex-col justify-center items-align px-5 py-5">
        <div className="text-left">
            Suscríbete a nuestro Boletín:
            </div>
            <div>
        <form className="inline-block align-items my-2" onSubmit={sendEmail} ref={form}>
            <input type="text" placeholder="Tu correo" className="px-2 py-2 rounded-lg text-left" onChange={(e) => setCorreo(e.target.value)} value={correo}>
            </input>
            <button type="submit" className="mx-2 bg-[#9a3324] text-white rounded-lg px-4 py-2">Suscribir</button>
        </form>
        </div>
        <h4
          className={[
            'text-large leading-normal text-green-600 font-bold break-words mb-20px',
            `${successMsgCss ? ' ' : ' hidden'}`,
          ].join('')}
        >
          Pronto nos pondremos en contacto.
        </h4>
    </div>
  )
}

export default Boletin