import React from "react";
import productosDB from "../database/productosDB";

function Quimicos3() {
  const productos = productosDB;

  const styles = {
    titulodivision:
      "w-[100%] sm:w-[400px] bg-[#154734] text-[#fff] font-bold sm:mt-16 mt-10 text-3xl rounded-t-2xl py-2 sm:py-4",
    box: "min-w-[45%] w-[90%] sm:max-w-[50%]",
    categoriaprod: "p-[20px] flex-col justify-center items-center border-t-4 w-[100%]",
    categoriaprodtitle:
      "m-auto w-[100%] sm:w-[60%] text-[#9a3324] font-bold text-2xl text-center pb-2",
    listatipoproductos: "m-auto w-[90%] sm:w-[70%] text-left",
    listasubproductos: "italic text-lg ",
    categoriatipoproductos: "pt-10",
  };

  const division = "Químicos";

  const productosLinea = productos.filter((row) => {
    return row.division === division;
  });

  let lineasUnicas = [];
  productosLinea.forEach((element) => {
    if (!lineasUnicas.includes(element.linea)) {
      lineasUnicas.push(element.linea);
    }
  });

  let familiasUnicas = [];
  let familiasUnicasI = [];
  productosLinea.forEach((element) => {
    if (!familiasUnicas.includes(element.familia)) {
      familiasUnicas.push(element.familia);
    }
  });

  // const productosBien = JSON.stringify(productosLinea);
  // const familiasBien = JSON.stringify(familiasUnicas);

  return (
    <div className="w-100 flex flex-col justify-center items-center"> <h1 className="text-center pt-10 font-semibold text-[#154734] text-4xl ">
    {division.toUpperCase()}
  </h1>
    <div className="flex  flex-col sm:flex-row flex-wrap justify-center items-center sm:items-start sm:w-[80%] w-[90%]">
      {/* <div className="w-100 relative">
        <img
          src="/pellets_banner.jpg"
          alt="banner-divisiones"
          className="w-[100%]"
        />
      </div> */}
      {/* <div>{productosBien}</div>
      <div>{familiasBien}</div> */}
     
      {lineasUnicas.map((lineaUnica, index) => (
        <div className="flex flex-col justify-center items-center w-[100%] sm:w-[45%]" key={index}>
          <div className={styles.titulodivision}>{lineaUnica}</div>
          <div className="flex flex-row flex-wrap justify-center sm:w-[90%] w-[100%] sm:max-w-[1600px]">
            <div className="hidden">{familiasUnicasI=[]}</div>
            {productosLinea
              .filter((rowFamilia) => {
                return rowFamilia.linea === lineaUnica;
              })
              .forEach((element) => {
                if (!familiasUnicasI.includes(element.familia)) {
                  familiasUnicasI.push(element.familia);
                }
              })}
            {familiasUnicasI.map((familiaUnica, index2) => (
              <div
                className="min-w-[45%] w-[100%] sm:max-w-[750px]"
                key={index2}
              >
                <div className={styles.categoriaprod}>
                  <div
                    className={styles.categoriaprodtitle}
                  >{`\u2022  ${familiaUnica}`}</div>
                  <div className={styles.listatipoproductos}>
                    <ul className={styles.listasubproductos}>
                      {productosLinea
                        .filter((rowLinea) => {
                          return rowLinea.familia === familiaUnica;
                        })
                        .map((b) => (
                          <li
                            className="hover:text-[#154734] hover:font-semibold"
                            key={b.producto_id}
                          >
                            <a
                              href={"/Producto?id=" + b.producto_id}
                            >{`\u2022  ${b.nombre}`}</a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      ;
    </div>
    </div>
  );
}

export default Quimicos3;
