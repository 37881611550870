import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BsList, BsX, BsWhatsapp } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import logo from "./../img/centurion.png";
import SearchBar from "./SearchBar";

function Header() {
  const styles = {
    navLinks:
      "hover:text-[#9a3324] pt-1 px-4 border-r-2 text-gray-700 border-gray-800 text-md font-semibold",
    socialIcons:
      "text-[#fff] bg-[#9a3324] px-3 py-3 mx-1 rounded-full hover:bg-[#154734] hover:p-4 ease-in-out duration-300",
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen((prev) => !prev, console.log(menuOpen));
  const menuRef = useRef();

  const handleOutsideClicks =useCallback((event)=>{
    if(menuOpen&& menuRef.current && !menuRef.current.contains(event.target)){
      setMenuOpen(false);
    };
    },[menuOpen]);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClicks);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleOutsideClicks);
    };

}, [menuOpen, handleOutsideClicks]);


//create a function in your component to handleOutsideClicks


  return (
    <header>
      <nav className="w-full h-24 bg-gradient-to-t from-[#d5d6d8] to-[#fff] ">
        {/*Desktop Menu*/}
        <div className="flex items-center justify-between h-full px-4 w-full">
          <a href="/">
            <img className="cursor-pointer sm:h-[70px]" src={logo} alt="logo" />
          </a>
          <div className="hidden sm:flex sm:justify-center sm:items-center">
            <ul className="hidden sm:flex ">
              <li className={styles.navLinks}>
                <a href="/Inicio">Inicio</a>
              </li>
              <li className={styles.navLinks}>
                <a href="/Divisiones">Divisiones</a>
              </li>
              <li className={styles.navLinks}>
                <a href="/Contacto">Contacto</a>
              </li>
              <li className={styles.navLinks}>
                <a href="/Ubicaciones">Ubicaciones</a>
              </li>
              <li className={styles.navLinks}>
                <a href="/Cotizar">Cotizar</a>
              </li>
              <a
                href="https://wa.me/525585560279?text=Hola%20amigo%20Centurión,%20tengo%20una%20consulta."
                className="px-4 mt-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsWhatsapp
                  size={30}
                  className="cursor-pointer text-[#fff] hover:bg-[#154734] ease-in-out duration-300 rounded-3xl bg-[#39a935] p-1"
                />
              </a>
            </ul>
          </div>
          <div className="hidden sm:flex">
            <SearchBar />
          </div>
          {/* <div className="items-center text-[#5b4be7] mx-4 hidden sm:flex">
            <h3 className="cursor-pointer border border-[#ffffff] px-4 py-1 rounded-full bg-rojo text-verde hover:bg-rojo hover:text-[#a15959] ease-in-out">
              EN
            </h3>
          </div> */}

          {/*Mobile Menu*/}
          <div onClick={toggleMenu} className="sm:hidden cursor pointer pl-24">
            <BsList className="h-8 w-8 text-[#0f2e12]" />
          </div>
        </div>

        <div
         ref={menuRef}
          className={
            menuOpen
              ? "fixed top-0 left-0 w-[75%] sm:hidden h-screen bg-[#d5d6d8] p-10 ease-in-out duration-500 z-30"
              : "fixed left-[-100%] top-0 p-10 ease-in-out duration-500"
          }
        >
          <div className="flex w-full items-center justify-end">
            <div onClick={toggleMenu} className="cursor-pointer">
              <BsX className="h-8 w-8 text-[#285f20]" />
            </div>
          </div>
          {/*Mobile Menu Links */}
          <div className="flex-col py-4 font-bold text-[#111022]">
            <img className="cursor-pointer h-[60px]" src={logo} alt="logo" />
            <ul>
              <li
                onClick={() => setMenuOpen(false)}
                className="py-4 hover:underline hover:decoration-[#075519]"
              >
                <a href="/Inicio">Inicio</a>
              </li>
              <li
                onClick={() => setMenuOpen(false)}
                className="py-4 hover:underline hover:decoration-[#075519]"
              >
                <a href="/Divisiones">Divisiones</a>
              </li>
              <li
                onClick={() => setMenuOpen(false)}
                className="py-4 hover:underline hover:decoration-[#075519]"
              >
                <a href="/Contacto">Contacto</a>
              </li>
              <li
                onClick={() => setMenuOpen(false)}
                className="py-4 hover:underline hover:decoration-[#075519]"
              >
                <a href="/Ubicaciones">Ubicaciones</a>
              </li>
              <li
                onClick={() => setMenuOpen(false)}
                className="py-4 hover:underline hover:decoration-[#075519]"
              >
                <a href="/Cotizar">Cotizar</a>
              </li>
            </ul>
          </div>
          <div>
            <SearchBar />
          </div>
          {/*Social media links */}
          <div className="flex flex-row justify-around pt-10 items-center text-verde">
            <div className="flex flex-row items-center justify-items-center py-2">
              <a
                href="https://www.facebook.com/profile.php?id=100057876390668"
                className={styles.socialIcons}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF size={20} className="" />
              </a>
              <a
                href="https://www.linkedin.com/company/qpcenturion/"
                className={styles.socialIcons}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn size={20} className="" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
