import React from "react";
import { useDispatch } from "react-redux";
import { removeFromCart } from "./redux/cartSlice";
import { FaWindowClose } from "react-icons/fa";

function ProductosCart({ item, key, no }) {
  const dispatch = useDispatch();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  return (
    <div className="flex justify-center w-[100%]">
      <div
        key={key}
        className={` w-[100%] py-4 px-4 m-2 rounded-xl outline outline-1 outline-[#b2b2b2] flex flex-row justify-between items-center${
          (no + 1) % 2 ? " bg-[#ebe9e9]" : " bg-[#ddd]"
        }`}
      >
        <div className="flex flex-row items-center sm:w-[80%] w-[90%]">
          <div className="w-[10%] text-[#9a3324] flex justify-center">
            <FaWindowClose className="hover:cursor-pointer" size={20} onClick={() => dispatch(removeFromCart({ id: item.producto_id }))} />
          </div>
          <div className="flex flex-col">
            <div className="text-left text-lg sm:text-2xl font-semibold py-2">{item.nombre}</div>
            <div className="flex flex-row">
              <p className="px-2 font-semibold">Presentación:</p>
              <p>{capitalizeFirstLetter(item.presentacion)}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center sm:w-[10%]">
          <div className="bg-[#fff] sm:px-8 px-4 py-2 rounded-lg outline outline-1 outline-[#979797]">{item.cantidad}</div>
        </div>

        {/* <button
          className="btn"
          onClick={() => dispatch(removeFromCart({ id: item.producto_id }))}
        >
          Remove
        </button> */}
      </div>
    </div>
  );
}

export default ProductosCart;
