import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';

function ContactForm() {
  const [nombre, setNombre] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [estado, setEstado] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [successMsgCss, setSuccesssMsg] = useState(false);

  const styles = {
    label: "mx-4 font-semibold break-words pt-2",
input:" rounded-md px-4 my-1 mx-4 bg-[#e8eeed] outline outline-1 outline-[#c9d4cf] focus:outline focus:outline-1"
  };

  // async function onSubmit(e) {
  //   e.preventDefault();
  //   const data = {
  //     nombre,
  //     empresa,
  //     ciudad,
  //     estado,
  //     correo,
  //     telefono,
  //     mensaje,
  //   };
  //   // console.log(data)
  //   try {
  //     const res = await fetch("/api/contact", {
  //       method: "POST",
  //       body: JSON.stringify(data),
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //     });
  //     if (!res.ok) {
  //       throw new Error("HTTP error! status: " + res.status);
  //     }
  //     const resData = await res.json();
  //     console.log(resData);
  //     setNombre("");
  //     setEmpresa("");
  //     setCiudad("");
  //     setEstado("");
  //     setCorreo("");
  //     setTelefono("");
  //     setMensaje("");
  //     setSuccesssMsg(true);
  //   } catch (err) {
  //     console.error("Error nene: " + err.message);
  //   }
  // }
  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();
    const data = {
          nombre,
          empresa,
          ciudad,
          estado,
          correo,
          telefono,
          mensaje,
        };

  emailjs.send('service_dxzof1g', 'template_tz33a3t', data, 'QzXcrbRMeQgiZ919G')
  .then((result) => {
      alert("Gracias por su mensaje");
      setNombre("");
          setEmpresa("");
          setCiudad("");
          setEstado("");
          setCorreo("");
          setTelefono("");
          setMensaje("");
          setSuccesssMsg(true);
  }, (error) => {
      console.log(error.text);
  });
};

  return (
      <div className="w-[100%]">
      
        <form
        ref={form}
          className="flex flex-col justify-center "
          onSubmit={sendEmail}
        >
          <label htmlFor="nombre-forma" className={styles.label}>Nombre completo*</label>
          <input
          className={styles.input}
            type="text"
            id="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            placeholder="Lic. Juan Pérez"
            required
          />

          <label htmlFor="empresa-forma" className={styles.label}>Empresa*</label>
          <input
          className={styles.input}
            type="text"
            id="empresa"
            value={empresa}
            onChange={(e) => setEmpresa(e.target.value)}
            placeholder="ACME"
            required
          />

          <label htmlFor="direccion-forma" className={styles.label}>¿De dónde nos contactas?</label>
          <input
          className={styles.input}
            type="text"
            id="ciudad"
            value={ciudad}
            onChange={(e) => setCiudad(e.target.value)}
            placeholder="Ciudad o Población"
          />
          <input
          className={styles.input}
            type="text"
            id="estado"
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
            placeholder="Estado"
          />

          <label htmlFor="correo-forma" className={styles.label}>Correo Electrónico*</label>
          <input
          className={styles.input}
            type="email"
            id="correo"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            placeholder="jperez@acme.com"
            required
          />

          <label htmlFor="telefono" className={styles.label}>Teléfono*</label>
          <input
          className={styles.input}
            type="telephone"
            id="telefono"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            required
          />

          <label htmlFor="Mensaje" className={styles.label}>Mensaje*</label>
          <textarea
            id="Mensaje"
            className={styles.input}
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
            rows={4}
            required
          ></textarea>
          <button id="submit" type="submit" value="Enviar" className="text-[#fff] bg-[#9a3324] w-20 mx-auto rounded-md h-8 my-4">
            Enviar
          </button>
        </form>
        <h4
          className={[
            'text-large leading-normal text-green-600 font-bold break-words mb-20px',
            `${successMsgCss ? ' ' : ' hidden'}`,
          ].join('')}
        >
          Nos pondremos en contacto lo más pronto posible.
        </h4>
      </div>
    
  );
}

export default ContactForm;
