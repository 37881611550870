import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./components/redux/store";
import Inicio from "./components/Inicio";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Ubicaciones from "./components/Ubicaciones";
import Contacto from "./components/Contacto";
import Widget from "./components/Widget";
import Producto from "./components/Producto";
import Cotizar from "./components/Cotizar";
import Busqueda from "./components/Busqueda";
import Divisiones2 from "./components/divisiones/Divisiones2";
import Lineas2 from "./components/divisiones/Lineas2";
import Grado2 from "./components/Grado2";


function App() {
  return (
    <Provider store={store}>
      <div className="App font-montserrat">
        <Header />
        <Widget />
        <Router>
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/Inicio" element={<Inicio />} />
            <Route path="/Divisiones" element={<Divisiones2 />} />
            <Route path="/Lineas/:division" element={<Lineas2 />} />
            <Route path="/Ubicaciones" element={<Ubicaciones />} />
            <Route path="/Contacto" element={<Contacto />} />
            <Route path="/Producto" element={<Producto />} />
            <Route path="/Cotizar" element={<Cotizar />} />
            <Route path="/Busqueda/:search" element={<Busqueda />} />
            <Route path="/Grado/:lineaPlastico" element={<Grado2 />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
