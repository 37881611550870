import React, { useState } from "react";

function Tabs2({ items }) {
    const [selectedTab, setSelectedTab]=useState(0);

   
    
  return (
    <div className="flex flex-col justify-center items-center py-4 w-full">
      <div className="flex justify-around sm:flex-row sm:flex-nowrap flex-wrap w-[100%]">
        {items.map((item, index) => (
          <button 
          // ref={index === 0 ? firstBtnRef : null}
          key={index}
          onClick={()=>setSelectedTab(index)}
          className={`text-md mx-1 py-2 font-bold w-full text-center ${selectedTab=== index ? " bg-[#154734] text-[#fff]" : "text-[#050505] bg-[#d9d9d9]"} `}>
            {item.title}
            </button>
        ))}
      </div>
      <div className="w-[90%] text-justify py-4">
        {items.map((item, index)=>(
            <div key={index} className={`${selectedTab === index ? "" : "hidden" }`}>
                 {item.content.length>10 ? item.content : 
                 (<div>{
                  item.content.map((content) =>
                    <div key={content.toString()}>
                      {content}
                    </div>
                )}
                 </div>)
                 }

                
            </div>
        ))}
      </div>
    </div>
  );
}

export default Tabs2;
