import React from 'react';
import { useState } from 'react';

function SearchBar() {
    const [palabra, setPalabra]=useState("");
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        window.location.href = "/Busqueda/"+palabra;
      }
      }
    ;
    
  return (
    <div className="flex flex-col sm:flex-row items-center">
        <input className="p-2 rounded-lg m-2 sm:mr-2 outline outline-1 outline-[#a5a2a2]" 
        placeholder="Buscar Producto..."
        type="text" required onChange={(e)=>{
            setPalabra(e.target.value);
        }} onKeyDown={handleKeyDown}/>
            
        <button className='bg-[#9a3324] px-4 py-2 rounded-xl text-[#fff] font-semibold'
        onClick={()=>{
            window.location.href = "/Busqueda/"+palabra;
            return false;    
        }}
        >
        Buscar
        </button>
    </div>
  )
}

export default SearchBar