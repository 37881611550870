import React from 'react';
import {
    BsCart3,
    BsFillEnvelopeFill,
    BsFillTelephoneFill,
    BsWhatsapp,
  } from "react-icons/bs";
  import{useSelector} from 'react-redux';

function Widget() {
    const phone="+525585560283";
    const cartItems= useSelector(state => state.cart.cartItems).length;
    return (
      <div className="fixed right-0 top-[33%] group z-50">
        <ul className="fixed right-[-100px] group-hover:right-[0%] ease-in-out duration-500">
          <li>
            <a href="/Cotizar" className="flex flex-row items-center bg-[#99c4a3] hover:border-[#f6f5f5] hover:border-2">
              <div className="px-2 py-2 bg-[#154734] border-solid border-2 border-white text-[#fff] font-semibold">
                <BsCart3 size={20} className="text-[#fff]" />
                {cartItems}
              </div>
              <div className="px-2 ">Cotiza</div>
            </a>
          </li>
          <li className="">
            <a href={`tel:${phone}`} className="flex flex-row items-center bg-[#99c4a3] hover:border-[#f6f5f5] hover:border-2">
              <div className="px-2 py-2 bg-[#154734] border-solid border-2 border-white">
                <BsFillTelephoneFill size={20} className="text-[#fff]" />
              </div>
              <div className="px-2 ">Llámanos</div>
            </a>
          </li>
          <li className="">
            <a href="mailto:irvin.ramirez@centurion.com.mx" className="flex flex-row items-center bg-[#99c4a3] hover:border-[#f5f3f3] hover:border-2">
              <div className="px-2 py-2 bg-[#154734] border-solid border-2 border-white">
                <BsFillEnvelopeFill size={20} className="text-[#fff]" />
              </div>
              <div className="px-2 ">Correo</div>
            </a>
          </li>
          <li className="">
            <a href="https://wa.me/525585560279?text=Hola%20amigo%20Centurión,%20tengo%20una%20consulta." target="_blank" rel="noopener noreferrer" className="flex flex-row items-center bg-[#99c4a3] hover:border-[#ffffff] hover:border-2">
              <div className="px-2 py-2 bg-[#154734] border-solid border-2 border-white">
                <BsWhatsapp size={20} className="text-[#fff]" />
              </div>
              <div className="px-2 ">Whatsapp</div>
            </a>
          </li>
        </ul>
      </div>
    );
}

export default Widget