import React from "react";
import productosDB from "./database/productosDB";
import { useDispatch } from "react-redux";
import { addToCart } from "./redux/cartSlice";
import Tabs4 from "./Tabs4";
import { useState } from "react";
import { useParams } from "react-router-dom";

function Grado2() {
  const [cantidad, setCantidad] = useState("100 kg");
  const dispatch = useDispatch();
  const productos = productosDB;
  let { lineaPlastico } = useParams();
  const styles = {
    linea:
      "flex-row justify-center  w-[90%] sm:w-[60%] items-center text-justify text-[#1d1d18]",
    cajaIzq: "text-left text-lg px-10 py-2 bg-[#c6c6c5]  w-[50%] m-1",
    cajaDer: "text-center text-lg bg-[#ececec] w-[50%] m-1 py-2",
  };

  // let grado = productos.filter((row) => {
  //   return (
  //     row.linea === lineaPlastico 
  //     && row.familia === "NA" 
  //     && row.nombre === "USO GENERAL"
  //   )
  // })[0];

  let grado= productos.filter((row) => {
    return (
      row.linea === lineaPlastico && row.familia === "NA" && row.nombre === "USO GENERAL"
    );
  })[0];
  

  let productosLinea = productos.filter((row) => {
    return (
      row.linea === lineaPlastico &&
      row.familia === "NA" &&
      row.nombre.toUpperCase() !== "USO GENERAL"
    );
  });

  let productosAll= productos.filter((row) => {
    return (
      row.linea === lineaPlastico &&
      row.familia === "NA"
    );
  });
  

  let plasticos = [];

  productosAll.forEach((a) => {
    plasticos.push(a.nombre);
  });

  let presentacion = [""];
  presentacion = grado.presentacion.split("/");

  const [selPresentacion, setSelPresentacion] = useState(presentacion[0] || "");
  const [producto, setProducto] = useState(plasticos[0] || "");

  let productoCotizar = {
    producto_id: productos.filter((row) => {
      return (
        row.linea === lineaPlastico &&
        row.familia === "NA" &&
        row.nombre === producto
      );
    })[0].producto_id,
    sinonimo: productos.filter((row) => {
      return (
        row.linea === lineaPlastico &&
        row.familia === "NA" &&
        row.nombre === producto
      );
    })[0].acronimo,
    nombre: producto,
    // cantidad:selCantidad,
    cantidad: cantidad,
    presentacion: selPresentacion,
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div>
      {/* <div>{id}</div> */}
      <div className="flex justify-center flex-col items-center">
        {/* {plasticos} */}
        <div className="flex flex-col w-[100%] sm:w-[60%] justify-center items-center py-4">
          <div className="text-xl font-bold bg-[#154734] text-[#fff] w-[90%] sm:w-[60%] py-4 m-4 flex flex-col">
            <div>{grado.linea.toUpperCase()}</div>
            <div className="text-lg">{grado.acronimo}</div>
          </div>

          <div className="flex justify-center flex-col items-center w-[100%] pt-4">
            <div className={styles.linea}>{grado.descripcion}</div>
            {/*              
              <div className=" flex flex-row pt-4 items-center  w-[90%] sm:w-[70%]">
                <div className="m-auto flex flex-row justify-center items-center text-[#9a3324] font-semibold text-md w-[50%]">
                  <FaRegFilePdf className="pr-2" size="30px" />
                  <a href="https://www.google.com">Ficha Técnica</a>
                </div>
                <div className="m-auto flex flex-row  justify-center items-center text-[#9a3324] font-semibold text-md w-[50%]">
                  <FaRegFilePdf className="pr-2" size="30px" />
                  <a href="https://www.google.com">Hoja de Seguridad</a>
                </div>
              </div> */}
          </div>
              {productosLinea.length>0?
              <div className="flex-col justify-center items-center w-[90%] sm:w-[60%]">
              <Tabs4 items={productosLinea} />
            </div>:<div></div>}
          
          <div className="flex flex-row justify-center items-center flex-wrap w-[80%] mb-4">
            {
              grado.sinonimo.split("/").map((marca, index)=>(
                <div className="flex justify-center items-center w-[25%]" key={index}>
              <img
                src={require('./marcas/'+marca+'.png')}
                alt={marca}
                className="w-[90%]"
              />
            </div>
              ))
            }
          </div>

          <div className="flex flex-col w-[100%] items-center pr-14">
            <div className="flex flex-row justify-center items-center w-[100%] sm:w-[60%] ">
              <div className="text-right text-lg font-semibold  w-[50%] m-1 py-2 pr-2">
                Producto:
              </div>
              <div className="text-center text-lg w-[50%] m-1 py-2">
                <select
                  id="selectPresentacion"
                  className="w-[80%] py-2 text-center border-2 border-[#bbbaba]"
                  onChange={(e) => setProducto(e.target.value)}
                >
                  {plasticos.map((index, element) => (
                    <option key={index} value={plasticos[element]}>
                      {capitalizeFirstLetter(plasticos[element])}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex flex-row justify-center items-center w-[100%] sm:w-[60%] ">
              <div className="text-right text-lg font-semibold  w-[50%] m-1 py-2 pr-2">
                Presentación:
              </div>
              <div className="text-center text-lg w-[50%] m-1 py-2">
                <select
                  id="selectPresentacion"
                  className="w-[80%] py-2 text-center border-2 border-[#bbbaba]"
                  onChange={(e) => setSelPresentacion(e.target.value)}
                >
                  {presentacion.map((index, element) => (
                    <option key={index} value={presentacion[element]}>
                      {capitalizeFirstLetter(presentacion[element])}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex flex-row justify-center items-center w-[80%] sm:w-[60%]">
              <div className="text-right text-lg font-semibold w-[50%] m-1 py-2 pr-2">
                Cantidad:
              </div>
              <div className="text-center text-lg w-[50%] m-1 py-2">
                <input
                  className="w-[70%] sm:w-[80%] py-2 text-center border-2 border-[#bbbaba] focus:outline-[#bbbaba] focus:outline-2 focus:outline"
                  type="text"
                  id="cantidad"
                  value={cantidad}
                  onChange={(e) => setCantidad(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="py-4">
            <button
              className="bg-[#9a3324] text-[#fff] text-center text-xl font-extrabold py-2 px-6 rounded-3xl"
              onClick={() => {
                dispatch(addToCart(productoCotizar));
                alert("El producto ha sido agregado a la solicitud");
              }}
            >
              COTIZAR AHORA
            </button>
          </div>
        </div>

        {/* <div>{JSON.stringify(productosFamilia)}</div> */}
        {/* <div>{JSON.stringify(productoCotizar)}</div> */}
      </div>
    </div>
  );
}

export default Grado2;
