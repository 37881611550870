import { createSlice } from "@reduxjs/toolkit";

const items=localStorage.getItem('cartItems') !== null ? JSON.parse(localStorage.getItem('cartItems')) : [];

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: items,
  },
  reducers: {

    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        (item) => item.producto_id === newItem.producto_id
      );
      if(!existingItem){
        state.cartItems.push(
            action.payload
          );
      };
        
      // localStorage.removeItem("cartItems");
      
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems
        .map((item=>item))
      ));
    },

    removeFromCart: (state, action) =>{
      state.cartItems=state.cartItems.filter(x=>x.producto_id !== action.payload.id)
      
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems
        .map((item=>item))
      ));
    },   

  },
});

export default cartSlice.reducer;
export const { addToCart, removeFromCart } = cartSlice.actions;
