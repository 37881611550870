import React from "react";
import { useParams } from "react-router-dom";
import productosDB from "./database/productosDB";

function Busqueda() {
  let { search } = useParams();
  search = search.replace(/[\u0300-\u036F]/g, '').toUpperCase();
  let productosBusqueda = productosDB.filter((row) => {
    return (
      (row.nombre && row.nombre.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toUpperCase().includes(search)) ||
      (row.name && row.name.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toUpperCase().includes(search)) ||
      // row.nombre.toUpperCase().includes(search) ||
      // row.name.toUpperCase().includes(search) ||
      (row.acronimo && row.acronimo.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toUpperCase().includes(search)) ||
      (row.sinonimo && row.sinonimo.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toUpperCase().includes(search)) ||
      (row.descripcion && row.descripcion.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toUpperCase().includes(search)) ||
      (row.usos && row.usos.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toUpperCase().includes(search))
      // row.sinonimo.toUpperCase().includes(search) ||
      // row.descripcion.toUpperCase().includes(search) ||
      // row.usos.toUpperCase().includes(search)
    );
    // if(row.nombre.includes(search)){
    //   return row.nombre;
    // }
  });
  return (
    <div>
      {/* Busqueda de {search} */}
      <div className="text-4xl text-[#154734] font-bold p-6">
        RESULTADO DE LA BÚSQUEDA
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="w-[90%] sm:w-[500px] bg-[#154734] text-[#fff] font-bold mt-4 text-3xl rounded-t-2xl py-4">
          {search}
        </div>
        <div className="w-[70%] border-t-4"></div>
        {productosBusqueda.length === 0 ? (
          <div className="text-lg font-semibold p-4">
            La búsqueda del término "{search}" no retornó ningún resultado.
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {/* <div>{JSON.stringify(productosBusqueda)}</div> */}
      <div className="flex flex-col justify-center items-center w-[100%]">
        {productosBusqueda.map((b) => (
          <div
            className="flex flex-row justify-center items-center w-[80%] sm:w-[75%] py-4 ml-[-6px]"
            key={b.id_producto}
          >
            <div className="bg-[#154734] rounded-l-xl w-[30px] min-h-[110px] relative left-[10px]"></div>
            <div className="flex flex-col justify-around rounded-lg outline outline-1 outline-[#a39f9f] shadow-xl w-[100%] min-h-[150px]">
              <div className="text-[#9a3324] text-left text-lg p-2 px-6 font-semibold">
                {b.nombre}:
              </div>
              <div className="text-justify px-6 py-2 text-sm">
                {b.descripcion}
              </div>
              <a
                className="text-[#9a3324] text-left text-sm p-2 px-6 font-semibold italic"
                href={
                  b.division === "Plásticos"
                    ? "/Grado/" + b.linea
                    : "/Producto?id=" + b.producto_id
                }
              >
                Leer más {">>"}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Busqueda;
