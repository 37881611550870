import React from "react";
import Carousel from "./Carousel";


function Inicio() {
  const styles = {
    cuadros:
      "flex flex-col m-2 sm:m-auto w-[350px] sm:w-[25%] h-[400px] justify-center items-center text-center group ",
    divisiones:
      "flex w-96 bg-[#fff] text-center my-4 h-60 border-4 border-[#249a6e] rounded-xl font-bold text-2xl text-[#1b1547] hover:text-3xl items-center justify-center ease-in-out duration-500",
    images:
      "sm:m-15 w-[200px] sm:w-[300px] h-[200px] justify-center items-center flex sm:py-2 py-10 m-10",
  };

  return (
    <div className="w-[100%] flex flex-col justify-center items-center">
      <div className="w-[100%] sm:pb-2">
        <Carousel />
      </div>

      <div className="flex flex-col justify-center items-center sm:py-5 pb-0 w-[85%]">
        
        <div className="text-5xl font-semibold text-[#124734] py-4">
          NOSOTROS
        </div>

        <div className="flex flex-col items-center sm:flex-row justify-center py-1">
          <div className="w-[200px]">
            <img src="/icono_nosotros.png" alt="experiencia"></img>
          </div>
          <div className="flex flex-col sm:text-left sm:w-[1000px]">
            <div className="text-[#9a3324] text-2xl font-bold italic font-barlow">
              EXPERIENCIA Y EFICIENCIA
            </div>
            <div className="text-[#9a3324] text-xl font-semibold italic font-barlow">
              Nuestra trayectoria de 25 Años
            </div>
            <div className="text-lg text-justify pt-2">
              Con un legado de 25 años en el mercado, nuestra compañía ha
              demostrado ser un referente de confiabilidad y eficiencia en la
              industria.
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center sm:flex-row justify-center py-1">
          <div className="w-[200px]">
            <img src="/iconos nosotros-02.png" alt="experiencia"></img>
          </div>
          <div className="flex flex-col sm:text-left sm:w-[1000px]">
            <div className="text-[#9a3324] text-2xl font-bold italic font-[Barlow]">
              PRECIO COMPETITIVO
            </div>
            <div className="text-[#9a3324] text-xl font-semibold italic font-[Barlow]">
              Valor y Calidad Garantizados
            </div>
            <div className="text-lg text-justify pt-2">
              Gracias a nuestro volumen de operación, conocimiento de mercado y
              eficiencia operativa, ofrecemos el mejor precio en el mercado.
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center sm:flex-row justify-center py-1">
          <div className="w-[200px]">
            <img src="/iconos nosotros-03.png" alt="inventario"></img>
          </div>
          <div className="flex flex-col sm:text-left sm:w-[1000px]">
            <div className="text-[#9a3324] text-2xl font-bold italic font-[Barlow]">
              INVENTARIO SÓLIDO
            </div>
            <div className="text-[#9a3324] text-xl font-semibold italic font-[Barlow]">
              Garantizando su abasto continuo
            </div>
            <div className="text-lg text-justify pt-2">
              Nuestra capacidad de entrega es un pilar fundamental de nuestro
              compromiso con nuestros clientes, respaldada por una red de 5
              almacenes que suman más de 20,000 metros cuadrados de espacio y un
              impresionante inventario de 5,000 toneladas en stock.
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center sm:flex-row justify-center py-1">
          <div className="w-[200px]">
            <img src="/iconos nosotros-04.png" alt="logistica"></img>
          </div>
          <div className="flex flex-col sm:text-left sm:w-[1000px]">
            <div className="text-[#9a3324] text-2xl font-bold italic font-[Barlow]">
              LOGÍSTICA DE VANGUARDIA
            </div>
            <div className="text-[#9a3324] text-xl font-semibold italic font-[Barlow]">
              Entregas puntuales
            </div>
            <div className="text-lg text-justify pt-2">
              Enfocados en la satisfacción de nuestros clientes, nos enorgullece
              ofrecer un compromiso de entrega de máximo 72 horas, garantizando
              así que sus operaciones nunca se detengan.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col m-auto sm:w-full sm:flex-row sm:py-5  justify-center justify-items-center uppercase text-[#fff] font-bold text-xl">
        <div className={styles.cuadros + "bg-[#9a3223]"}>
          <h1 className="text-5xl group-hover:text-6xl ease-in-out duration-300 p-4">
            20,000
          </h1>
          <p className="text-2xl group-hover:text-2xl ease-in-out duration-500">
            m2 de almacenes
          </p>
        </div>
        <div className={styles.cuadros + "bg-[#ab4837]"}>
          <h1 className="text-5xl group-hover:text-6xl ease-in-out duration-300 p-4">
            5,000
          </h1>
          <p className="text-xl group-hover:text-2xl ease-in-out duration-500">
            toneladas de inventario disponible
          </p>
        </div>
        <div className={styles.cuadros + "bg-[#b65c4a]"}>
          <h1 className="text-5xl group-hover:text-6xl ease-in-out duration-300 p-4">
            5
          </h1>
          <p className="text-xl group-hover:text-2xl ease-in-out duration-500">
            Almacenes a lo largo de México
          </p>
        </div>
        <div className={styles.cuadros + "bg-[#c2715f]"}>
          <h1 className="text-5xl group-hover:text-6xl ease-in-out duration-300 p-4">
            72
          </h1>
          <p className="text-xl group-hover:text-2xl ease-in-out duration-500">
            horas máximas para entrega
          </p>
        </div>
      </div>
      <div className="py-5">
        <h2 className="pb-5 text-center text-5xl font-semibold text-[#154734]">
          Nuestras diferentes divisiones:
        </h2>

        <div className="flex flex-wrap justify-evenly">

          <a href="/Lineas/Químicos" className="flex flex-col my-4 mx-12">
            <div className="flex flex-row justify-between items-center">
              <div className="bg-[#154734] text-[#fff] font-bold text-2xl sm:text-3xl rounded-t-2xl  px-auto py-2 w-[60%]">QUÍMICOS</div>
              <div className="w-[38%] h-[10px] bg-[#9a3324]"></div>
            </div>
            <div className="">
              <img src="/quimicos.jpg" alt="quimicos" className="w-[500px] sm:h-[350px]"/>
            </div>
          </a>
          
          <a href="/Lineas/Plásticos" className="flex flex-col my-4 mx-12">
            <div className="flex flex-row justify-between items-center">
              <div className="bg-[#154734] text-[#fff] font-bold text-2xl sm:text-3xl rounded-t-2xl  px-auto py-2 w-[60%]">PLÁSTICOS</div>
              <div className="w-[38%] h-[10px] bg-[#9a3324]"></div>
            </div>
            <div className="">
              <img src="/plasticos.jpg" alt="plasticos" className="w-[500px] sm:h-[350px]"/>
            </div>
          </a>

          {/* <a href="/Divisiones" className="flex flex-col my-4 mx-12">
            <div className="flex flex-row justify-between items-center">
              <div className="bg-[#154734] text-[#fff] font-bold text-2xl sm:text-3xl rounded-t-2xl  px-auto py-2 w-[60%]">ADHESIVOS</div>
              <div className="w-[38%] h-[10px] bg-[#9a3324]"></div>
            </div>
            <div className="">
              <img src="/adhesivos.jpg" alt="adhesivos" className="w-[500px] sm:h-[350px]"/>
            </div>
          </a>

          <a href="/Divisiones" className="flex flex-col my-4 mx-12">
            <div className="flex flex-row justify-between items-center">
              <div className="bg-[#154734] text-[#fff] font-bold text-2xl sm:text-3xl rounded-t-2xl  px-auto py-2 w-[60%]">PELÍCULAS</div>
              <div className="w-[38%] h-[10px] bg-[#9a3324]"></div>
            </div>
            <div className="">
              <img src="/peliculas.jpg" alt="peliculas" className="w-[500px] sm:h-[350px]"/>
            </div>
          </a>

          <a href="/Divisiones" className="flex flex-col my-4 mx-12">
            <div className="flex flex-row justify-between items-center">
              <div className="bg-[#154734] text-[#fff] font-bold text-2xl sm:text-3xl rounded-t-2xl  px-auto py-2 w-[60%]">LÁMINAS</div>
              <div className="w-[38%] h-[10px] bg-[#9a3324]"></div>
            </div>
            <div className="">
              <img src="/laminas.jpg" alt="laminas" className="w-[500px] sm:h-[350px]"/>
            </div>
          </a>

          <a href="/Divisiones" className="flex flex-col my-4 mx-12">
            <div className="flex flex-row justify-between items-center">
              <div className="bg-[#154734] text-[#fff] font-bold text-2xl sm:text-3xl rounded-t-2xl  px-auto py-2 w-[60%]">SPUN BONDS</div>
              <div className="w-[38%] h-[10px] bg-[#9a3324]"></div>
            </div>
            <div className="">
              <img src="/spunbonds.jpg" alt="spunbonds" className="w-[500px] sm:h-[350px]"/>
            </div>
          </a> */}

        </div>

      </div>
      {/* <div className="flex flex-col justify-center w-[100%]">
        <div className="text-center text-5xl font-semibold text-[#154734] pb-8">
          Representamos a:
        </div>
        <div className="flex sm:flex-row items-center sm:flex-wrap sm:justify-around flex-col pt-10 sm:mx-7 w-[100%]">
          <div className={styles.images}>
            <img src="/lotte.jpg" alt="lotte" className="w-[250px] h-[200px]" />
          </div>
          <div className={styles.images}>
            <img src="/wanhua.jpg" alt="wanhua" className="w-[250px] h-[200px]" />
          </div>
          <div className={styles.images}>
            <img src="/formosa.jpg" alt="formosa" className="w-[250px] h-[200px]" />
          </div>
          <div className={styles.images}>
            <img src="/zislloy.jpg" alt="zislloy" className="w-[250px] h-[150px]" />
          </div>
          <div className={styles.images}>
            <img src="/huafon.jpg" alt="huafon"className="w-[250px] h-[150px]" />
          </div>
          <div className={styles.images}>
            <img
              src="/changchun.jpg"
              alt="changchun"
              className="w-[250px] h-[200px]"
            />
          </div>
          <div className="m-10 w-[400px] h-[200px] items-center justify-center flex">
            <img src="/lgchem.jpg" alt="lgchem" className="w-[350px] h-[100px]" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Inicio;
