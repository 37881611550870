import React, { useState } from "react";

function Tabs4({ items }) {
  const [selectedTab, setSelectedTab] = useState(0);
  // let descripcion=[];
  // descripcion = items.descripcion.split("/");

  return (
    <div className="flex flex-col justify-center items-center py-4 w-full">
      <div className="flex justify-center items-center flex-col w-[100%] border-b-4 border-[#cdd7d2] border-dashed py-4">
        <div className="flex justify-center flex-col w-[100%] sm:w-[50%] m-8 ">
          {items.map((item, index) => (
            <button
              // ref={index === 0 ? firstBtnRef : null}
              key={index}
              onClick={() => setSelectedTab(index)}
              className={`text-md mb-1 py-2 font-bold w-full text-center ${
                selectedTab === index
                  ? " bg-[#9e3223] text-[#fff]"
                  : "text-[#124734] bg-[#CDD7D2]"
              } ${
                index === 0
                  ? items.length===1 ? "rounded-2xl"
                  : " rounded-t-2xl"
                  : index === items.length - 1
                  ? " rounded-b-2xl"
                  : ""
              }`}
            >
              {item.nombre}
            </button>
          ))}
        </div>
      </div>
      <div className="w-[90%] text-justify py-4">
        {items.map((item, index) => (
          <div
            key={index}
            className={`${selectedTab === index ? "" : "hidden"}`}
          >
            <div className="flex justify-center items-center text-[#9e3223] font-bold text-3xl text-center">
              {item.nombre}
            </div>
            <div
          className="flex flex-row justify-center items-center w-[100%] py-4 ml-[-10px]"
          key={item.producto_id}
        >
            <div className="bg-[#154734] rounded-l-xl w-[30px] min-h-[110px] relative left-[10px]"></div>
            <div className="flex flex-col justify-around rounded-lg outline outline-1 outline-[#a39f9f] bg-[#f6f6f6] shadow-lg shadow-[#a8a7a7] w-[100%] min-h-[150px] text-[#575756]">
              <div className="text-center px-6 pt-4 text-md">
              {item.descripcion.split("*").map((content) => (
                <div className="pb-4" key={content.toString()}>{content}</div>
              ))}
              </div>
            </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tabs4;
