import React from "react";
import ContactForm from "./ContactForm";

function Contacto() {
  return (
    <div>
      <div className="">
        <img
          src="/banner-contacto.jpg"
          alt="banner-ubicaciones"
          className="w-[100%]"
        />
      </div>
      <div className="flex flex-col sm:flex-row py-2  justify-center items-center">
        <div className="w-[90%] sm:w-[600px] flex justify-end px-4">
          <ContactForm />
        </div>
        <div className="flex justify-start sm:w-[600px] w-[95%] px-4">
          
          <iframe
            title="googleMap"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.296875237572!2d-99.12643702596345!3d19.485859238980158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1e29e1086038b%3A0x43debd2a4b7882fa!2zUXXDrW1pY29zIFkgUGzDoXN0aWNvcyBDZW50dXJpw7Nu!5e0!3m2!1ses-419!2smx!4v1702604395887!5m2!1ses-419!2smx"
            width="650"
            height="530"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contacto;
